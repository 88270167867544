import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import EvaluacionReparacionComponenteDetallesChecks from "./EvaluacionReparacionComponenteDetallesChecks";
import { EvaluacionOrReparacionComponenteEtapa, TareaCheck } from "../../models/EvaluacionesReparaciones";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";
import { getRequestsAndGenerateInforme } from "../../utils/evaluacionesReparaciones/evaluacionesReparaciones";

interface Props {
    codigoOP: string,
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    nombreTarea: string,
    isBusy: boolean,
    changeIsBusy: (state: boolean) => void,
    updateEvaluacionReparacionComponenteEtapaTarea: (hideModal: () => void) => void,
    detallesApi: DetallesEvaluacionReparacionApi
};

const EvaluacionReparacionComponenteDetallesGenerarInforme = ({
    isNotEditable,
    changeEvaluacionReparacionComponenteEtapa,
    evaluacionReparacionComponenteEtapa,
    nombreTarea,
    isBusy,
    changeIsBusy,
    updateEvaluacionReparacionComponenteEtapaTarea,
    codigoOP,
    detallesApi
}: Props): ReactElement => {

    const getAprobacionState = (): TareaCheck => {
        const response: TareaCheck = { name: '¿Aprobado?', check: false, select: true };
        const currentState = evaluacionReparacionComponenteEtapa.tareas.find((tarea) => tarea.nombre === nombreTarea)?.check;
        if (currentState && currentState.check) {
            response.check = true;
        }
        return response;
    }
    
    const [ isDownloadingImages, setIsDownloadingImages ] = useState<boolean>(false);
    const [ aprobacionState, setAprobacionState ] = useState<TareaCheck>(getAprobacionState());
    const [ show, setShow ] = useState<boolean>(false);
    const hideModal = () => setShow(false);
    const etapasStatusElements = ((): ReactElement[] => {
        const response: ReactElement[] = [];
        evaluacionReparacionComponenteEtapa.evaluacionReparacionEtapasStatus?.forEach((etapaStatus) => {
            if (!etapaStatus.isAproved || !etapaStatus.isComplete) {
                response.push(
                    <>
                        <li>{etapaStatus.etapa}</li>
                        <ul>
                            {etapaStatus.tareasStatus.map((tareaStatus) => (
                                <li>{tareaStatus.name}: {!tareaStatus.isIncomplete ? '' : <b>Incompleta</b>}{!tareaStatus.isNotAproved ? '' : <>{tareaStatus.isIncomplete ? ', ' : ''}<b>No aprobada</b></>}</li>
                            ))}
                        </ul>
                    </>
                );
            }
        });
        return response;
    })();
    const areThereTareasPending = evaluacionReparacionComponenteEtapa.evaluacionReparacionEtapasStatus?.some((etapa) => !etapa.isComplete || !etapa.isAproved);
    const isInformeNotBuildable = areThereTareasPending || isBusy;

    useEffect(() => {
        setAprobacionState(getAprobacionState());
    }, [show]);

    return (
        <>
            <Button onClick = {() => setShow(true)} disabled = {isNotEditable}>
                {!isBusy ? 'Revisión' : <Spinner animation = "border" size = "sm"/>}
            </Button>
            {show &&
                <Modal show = {show} onHide = {hideModal}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>{nombreTarea}</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body className = "cards-body-groups">
                        {aprobacionState && 
                            <EvaluacionReparacionComponenteDetallesChecks
                                tareaCheck = {aprobacionState}
                                isNotEditable = {isInformeNotBuildable}
                                // changeEvaluacionComponenteEtapa = {changeEvaluacionComponenteEtapa}
                                evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                nombreTarea = {nombreTarea}
                                changeAprobacionState = {(check: TareaCheck) => setAprobacionState(check)}
                            />
                        }
                        {areThereTareasPending &&
                            <div>
                                <h5>Tareas pendientes:</h5>
                                <ul>{etapasStatusElements}</ul>
                            </div>
                        }
                        {isDownloadingImages &&
                            <div className = "text-center">
                                ... Descargando fotos <Spinner animation="border" size="sm" />
                            </div>
                        }
                        <div className = "modal-buttons">
                            <Button disabled = {isInformeNotBuildable} onClick = {() => {
                                changeIsBusy(true);
                                getOrdenProceso(codigoOP)
                                    .then(async (ordenProcesoResponse) => {
                                        if (ordenProcesoResponse.data) {
                                            await getRequestsAndGenerateInforme(ordenProcesoResponse.data, detallesApi, changeIsBusy, setIsDownloadingImages);
                                        } else {
                                            cogoToast.error('No fue posible generar el informe');
                                        }
                                        changeIsBusy(false);
                                    })
                                    .catch(() => {
                                        changeIsBusy(false);
                                        cogoToast.error('No fue posible generar el informe');
                                    })
                            }}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Vista previa'}
                            </Button>
                            <Button
                                onClick = {() => {
                                    changeEvaluacionReparacionComponenteEtapa({ ...evaluacionReparacionComponenteEtapa, tareas: evaluacionReparacionComponenteEtapa.tareas.map((tarea) => {
                                        let response = tarea;
                                        if (response.nombre === nombreTarea) {
                                            response.check = aprobacionState;
                                        }
                                        return response;
                                    })});
                                    updateEvaluacionReparacionComponenteEtapaTarea(hideModal);
                                    window.location.reload();
                                }}
                                variant = 'success'
                                disabled = {isInformeNotBuildable}
                            >
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default EvaluacionReparacionComponenteDetallesGenerarInforme;