import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Archivo } from "../../models/Archivos";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones";
import { formatProcesoPhotoRequest } from "../../utils/evaluaciones/evaluaciones";
import UploadImageProceso from "./UploadImageProceso";
import imageCompression from "browser-image-compression";
import { fileToBase64, rotateImageBase64 } from "../../utils/archivos";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";

interface ProcesoImageComponentProps {
    etapa: string,
    tarea: string,
    variable: string,
    isNotEditable: boolean,
    userName: string,
    changeIsUpdating: (state: boolean) => void,
    evaulacionReparacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa
    changeEvaluacionReparacionComponenteEtapaResumen: (evaulacionReparacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void,
    detallesApi: DetallesEvaluacionReparacionApi
}

const ProcesoImageComponent = ({
    etapa, tarea, variable, isNotEditable, userName, changeIsUpdating, evaulacionReparacionComponenteResumen, changeEvaluacionReparacionComponenteEtapaResumen, detallesApi
}: ProcesoImageComponentProps): ReactElement => {

    const [ imagen, setImagen ] = useState<Archivo>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const updateResumen = () => changeEvaluacionReparacionComponenteEtapaResumen({
        ...evaulacionReparacionComponenteResumen,
        tareas: evaulacionReparacionComponenteResumen.tareas.map((tareaUpdate) => {
            let tareaResponse = tareaUpdate;
            if (tareaResponse.nombreTarea === tarea) {
                tareaResponse = {
                    ...tareaResponse,
                    valores: tareaResponse.valores.map((valor) => {
                        let valorResponse = valor;
                        if (valorResponse.columna === 'Realizado por' && !valorResponse.valor?.includes(userName)) {
                            valorResponse.valor = valorResponse.valor ? `${valorResponse.valor} / ${userName}` : userName;
                        }
                        return valorResponse;
                    })
                }
            }
            return tareaResponse;
        })
    });

    const onUpload = (imagen: Blob, name: string) => {
        changeIsUpdating(true);
        imageCompression(new File([imagen], name, { type: imagen.type }), { maxSizeMB: 1, maxWidthOrHeight: 640, useWebWorker: true })
            .then((compressedImage) => {
                fileToBase64(compressedImage)
                    .then((compressedImageBase64) => {
                        rotateImageBase64(compressedImageBase64, imagen.type)
                            .then((compressedImageBase64Adjusted) => {
                                detallesApi.postImagenProceso(formatProcesoPhotoRequest(etapa, tarea, variable, userName, { nombre: name, base64: compressedImageBase64Adjusted }))
                                    .then((response) => {
                                        if (response.data.base64) {
                                            setImagen(response.data);
                                            updateResumen();
                                            cogoToast.success('Imagen añadida');   
                                        } else {
                                            cogoToast.error('No fue posible subir la imagen');
                                        }
                                        changeIsUpdating(false);
                                    })
                                    .catch(() => {
                                        cogoToast.error('No fue posible subir la imagen');
                                        changeIsUpdating(false);
                                    })
                            }).catch(() => {
                                changeIsUpdating(false);
                                cogoToast.error('No fue posible ajustar la imagen para subirla al servidor');
                            })
                    }).catch(() => {
                        changeIsUpdating(false);
                        cogoToast.error('No fue posible transformar la imagen para subirla al servidor');
                    });
            })
            .catch(() => {
                changeIsUpdating(false);
                cogoToast.error('No fue posible comprimir la imagen para subirla al servidor');
            });
    }

    const onDelete = () => {
        changeIsUpdating(true);
        detallesApi.deleteImagenProceso(formatProcesoPhotoRequest(etapa, tarea, variable, userName))
            .then(() => {
                setImagen(undefined);
                updateResumen();
                changeIsUpdating(false);
                cogoToast.success('Imagen eliminada!');
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible eliminar la imagen');
            })
    }

    useEffect(() => {
        detallesApi.getImagenProceso(formatProcesoPhotoRequest(etapa, tarea, variable))
            .then((response) => {
                if (response.data.nombre) {
                    setImagen(response.data);
                }
                setIsLoading(false);
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener la imagen del componente');
                setIsLoading(false);
            })
    }, [etapa, tarea, variable]);

    return (
        <UploadImageProceso
            title = {variable}
            image = {imagen}
            onDocumentUpload = {onUpload}
            onDocumentDelete = {onDelete}
            isLoading = {isLoading}
            notEditable = {isNotEditable}
        />
    );
}

export default ProcesoImageComponent;